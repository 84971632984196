@use 'sass:math';

/// Removes the unit from a number
///
/// @param {number} $number the number to remove the unit from
@function strip-unit($number, $unit: 1px) {
	@return calc($number / ($number * 0 + $unit));
}

/// Converts pixel units to rem units using the specified font size
///
/// @param {number} $pxValue
///     The value to convert to rem
/// @param {number} $font-size
///     The base font size for rem calculation (default 16px)
@function pixel-to-rem($pxValue, $font-size: 16px) {
	// check if units are pixel values
	@if math.unit($pxValue) != 'px' {
		@error ("Parameter $pxValue must be in px units but has unit #{math.unit($value)}");
	}
	@if math.unit($font-size) != 'px' {
		@error ("Parameter $font-size must be in px units but has unit #{math.unit($font-size)}");
	}

	@return calc(strip-unit($pxValue) / strip-unit($font-size)) * 1rem;
}

$navigation-item-height: pixel-to-rem(56px);
$header-z-index: 1;
