@use 'br24-common';
@use 'spacing';

.button {
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	height: br24-common.$navigation-item-height;
	padding: 0 spacing.$s400;
	border: none;

	text-overflow: ellipsis;
	white-space: nowrap;

	background-color: transparent;
}

.text {
	overflow: hidden;
	text-overflow: ellipsis;
}

.chevron {
	margin-left: auto;
	transition: transform 200ms ease-in-out;

	&.up {
		transform: rotate(180deg);
	}

	&.down {
		transform: rotate(0);
	}
}
