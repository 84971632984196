@use 'breakpoints';
@use 'spacing';
@use '../FooterVariables';

.footer {
	display: flex;
	flex-direction: column;
}

.newsletterSection.newsletterSection {
	border-bottom: none;
}

.scrollTop {
	position: relative;
	top: -(FooterVariables.$scroll-top-button-dimension / 2);

	// the padding should be 400 but we have to account for the button that is moved to the top
	margin-bottom: -(FooterVariables.$scroll-top-button-dimension / 2) + spacing.$s400;
}
