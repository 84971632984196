@use 'spacing';
@use './HeaderVariables';

.navigation {
	display: none;
	visibility: hidden;

	&.open {
		display: block;
		visibility: visible;
	}
}

.navigationTabs {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	height: 100%;
	max-height: calc(100vh - HeaderVariables.$mobile-header-height);

	background-color: var(--background-default-third);
}

.themeSelectorCaption {
	align-self: flex-start;
	height: HeaderVariables.$main-navigation-item-height;
	padding: 0 spacing.$s300;
	line-height: HeaderVariables.$main-navigation-item-height;
}
