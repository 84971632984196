.image {
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
	transition-property: transform, filter;
}

.imageZoomEnabled {
	&:focus-visible {
		& .image {
			transform: scale(1.03);
			filter: brightness(110%);
		}
	}

	@media screen and (hover: hover) and (pointer: fine) {
		&:hover .image {
			transform: scale(1.03);
			filter: brightness(110%);
		}
	}
}
