@use 'br24-common';

.xs {
	width: br24-common.pixel-to-rem(16px);
	height: br24-common.pixel-to-rem(16px);
}

.s {
	width: br24-common.pixel-to-rem(20px);
	height: br24-common.pixel-to-rem(20px);
}

.m {
	width: br24-common.pixel-to-rem(24px);
	height: br24-common.pixel-to-rem(24px);
}

.l {
	width: br24-common.pixel-to-rem(32px);
	height: br24-common.pixel-to-rem(32px);
}

.xl {
	width: br24-common.pixel-to-rem(48px);
	height: br24-common.pixel-to-rem(48px);
}
