/// This file defines how links are rendered throughout the page. It
/// specifies utility classes to allow to especially style the hover
/// and focus indication states consistently throughout the page.
///
/// The main idea is that there are dark areas on the page (header, footer,
/// bavaria map, ...) and areas that will follow the light dark mode of
/// the page. So the utilities will allow to set the link style for such
/// a section of the page, without having to redefine the link styles
/// for every instance of a link
///
/// The idea of this architecture is to define the link color behavior
/// once and use variables for the colors. These variables will then be
/// overwritten by setting a utility class for always dark or light sections

@use 'theme-colors';

@mixin common-link-behavior {
	color: currentColor;
	text-decoration: none;
	outline: none;

	&.animate-hover,
	.animate-hover & {
		transition: color 200ms ease-in-out;
	}

	// active is important for mobile devices as it will highlight the click on a link
	&:active:not(.no-active),
	&:focus:not(.no-focus) {
		color: var(--link-highlight);
	}

	@media screen and (hover: hover) and (pointer: fine) {
		&:hover:not(.no-hover),
		&:visited:hover:not(.no-hover) {
			color: var(--link-highlight);

			&.underline-hover,
			.underline-hover & {
				text-decoration: underline;
			}
		}
	}
}

:root {
	--outline-width: 3px;
}

:root,
.outline-outside {
	// default outline offset. can be overwritten if necessary
	--outline-offset: var(--outline-width);
}

.outline-inside {
	// move the outline offset into the element
	--outline-offset: calc(-1 * var(--outline-width));
}

[data-theme='light'],
[data-theme-area='light'] {
	// corresponds to --text-highlight-second
	--link-highlight: #{theme-colors.$color-secondary-09};
}

[data-theme='dark'],
[data-theme-area='dark'] {
	// corresponds to --text-highlight-second
	--link-highlight: #{theme-colors.$color-primary-02};
}

* {
	&:active {
		outline: none;
	}

	// default focus visible behavior for all elements
	// depends on the theme of the region
	&:focus-visible {
		outline: var(--outline-width) double var(--link-highlight);
		outline-offset: var(--outline-offset);
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}
}

a {
	&:visited {
		color: currentColor;
	}

	@include common-link-behavior;
}

button.button-link {
	cursor: pointer;
	border: none;
	background-color: transparent;

	&:focus-visible {
		outline: var(--outline-width) double var(--link-highlight);
		outline-offset: var(--outline-offset);
	}

	@include common-link-behavior;
}
