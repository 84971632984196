// This file is autogenerated from the values exported from Figma
// DO NOT MODIFY BUT ONLY UPDATE BY RUNNING THE GENERATION SCRIPT.

@import 'theme-colors';

:root,
[data-theme='light'] {
	--background-button-first: #{$color-secondary-06};
	--background-button-fourth: #{$color-secondary-01};
	--background-button-fourth-hover: #{$color-secondary-03};
	--background-button-second: #{$color-secondary-05};
	--background-button-third: #{$color-neutral-white};
	--background-consistent-first: #{$color-secondary-06};
	--background-consistent-second: #{$color-secondary-09};
	--background-default-fifth: #{$color-secondary-10};
	--background-default-first: #{$color-neutral-white};
	--background-default-fourth: #{$color-secondary-05};
	--background-default-second: #{$color-neutral-01};
	--background-default-seventh: #{$color-secondary-02};
	--background-default-sixth: #{$color-neutral-01};
	--background-default-third: #{$color-secondary-06};
	--background-highlight-first: #{$color-primary-02};
	--background-highlight-hover: #{$color-primary-03};
	--background-highlight-second: #{$color-secondary-09};
	--background-highlight-weather: #{$color-secondary-06};
	--background-icon-first: #{$color-neutral-white};
	--background-icon-second: #{$color-secondary-01};
	--background-live: #{$color-primary-02};
	--icon-consistent-od-01: #{$color-neutral-white};
	--icon-consistent-od-02: #{$color-secondary-02};
	--icon-consistent-ol: #{$color-neutral-black};
	--icon-default-first: #{$color-neutral-black};
	--icon-default-second: #{$color-neutral-05};
	--icon-default-third: #{$color-secondary-06};
	--icon-highlight: #{$color-primary-02};
	--icon-invert: #{$color-neutral-white};
	--text-consistent-od-01: #{$color-neutral-white};
	--text-consistent-od-02: #{$color-secondary-02};
	--text-consistent-ol: #{$color-neutral-black};
	--text-default-first: #{$color-neutral-black};
	--text-default-fourth: #{$color-neutral-black};
	--text-default-second: #{$color-neutral-05};
	--text-default-third: #{$color-secondary-06};
	--text-highlight-first: #{$color-primary-02};
	--text-highlight-second: #{$color-secondary-09};
	--text-invert: #{$color-neutral-white};
}

[data-theme='dark'] {
	--background-button-first: #{$color-primary-02};
	--background-button-fourth: #{$color-secondary-05};
	--background-button-fourth-hover: #{$color-secondary-04};
	--background-button-second: #{$color-secondary-05};
	--background-button-third: #{$color-neutral-white};
	--background-consistent-first: #{$color-secondary-06};
	--background-consistent-second: #{$color-secondary-09};
	--background-default-fifth: #{$color-secondary-08};
	--background-default-first: #{$color-secondary-07};
	--background-default-fourth: #{$color-secondary-07};
	--background-default-second: #{$color-secondary-08};
	--background-default-seventh: #{$color-secondary-10};
	--background-default-sixth: #{$color-secondary-04};
	--background-default-third: #{$color-secondary-10};
	--background-highlight-first: #{$color-primary-02};
	--background-highlight-hover: #{$color-primary-01};
	--background-highlight-second: #{$color-primary-02};
	--background-highlight-weather: #{$color-secondary-08};
	--background-icon-first: #{$color-neutral-white};
	--background-icon-second: #{$color-secondary-01};
	--background-live: #{$color-primary-02};
	--icon-consistent-od-01: #{$color-neutral-white};
	--icon-consistent-od-02: #{$color-secondary-02};
	--icon-consistent-ol: #{$color-neutral-black};
	--icon-default-first: #{$color-neutral-white};
	--icon-default-second: #{$color-secondary-03};
	--icon-default-third: #{$color-neutral-white};
	--icon-highlight: #{$color-primary-02};
	--icon-invert: #{$color-neutral-black};
	--text-consistent-od-01: #{$color-neutral-white};
	--text-consistent-od-02: #{$color-secondary-02};
	--text-consistent-ol: #{$color-neutral-black};
	--text-default-first: #{$color-neutral-white};
	--text-default-fourth: #{$color-secondary-01};
	--text-default-second: #{$color-secondary-03};
	--text-default-third: #{$color-neutral-white};
	--text-highlight-first: #{$color-primary-02};
	--text-highlight-second: #{$color-primary-02};
	--text-invert: #{$color-neutral-black};
}
