@use 'breakpoints';
@use 'spacing';

.closeButton {
	cursor: pointer;

	position: relative;

	flex-shrink: 0;
	align-self: start;

	margin-top: spacing.$s300;
	margin-right: spacing.$s300;
	border-width: 0;

	color: var(--text-consistent-od-01);

	appearance: none;
	background-color: transparent;
}

.content {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;

	width: 100%;
	padding: spacing.$s300 spacing.$s600;
}

.wrapper {
	position: sticky;

	display: flex;

	width: 100%;
	max-width: breakpoints.$xl;

	color: var(--text-consistent-od-01);

	background-color: var(--background-button-second);
}
