@use 'breakpoints';
@use 'spacing';
@use 'br24-common';

/// Utility to provide a fallback for browsers that do not support
/// container queries
///
/// See https://www.smashingmagazine.com/2021/05/css-container-queries-use-cases-migration-strategies/
/// for an explanation of the technique
@mixin container-query-with-fallback($breakpoint) {
	// fallback if container queries are not supported
	@supports not (contain: inline-size) {
		@media only screen and (min-width: $breakpoint) {
			@content;
		}
	}

	@container (min-width: #{$breakpoint} ) {
		@content;
	}
}

.link {
	display: block;
	margin: 0 0 spacing.$s800 0;

	&:last-of-type {
		margin-bottom: 0;
	}

	// use container queries if supported
	@supports (contain: inline-size) {
		container: article-list-item / inline-size;
	}

	@media screen and (hover: hover) and (pointer: fine) {
		&:hover .title {
			color: var(--text-highlight-second);
			text-decoration: underline;
		}
	}
}

.wrapper {
	display: flex;
	flex-flow: column nowrap;

	// fallback if container queries are not supported
	@include container-query-with-fallback(breakpoints.$md) {
		flex-direction: row;
		gap: spacing.$s400;
	}
}

.body {
	display: flex;
	flex: 1.5 1;
	flex-direction: column;
	padding: spacing.$s200;

	@include container-query-with-fallback(breakpoints.$md) {
		padding: 0;
	}
}

.header {
	display: flex;
	flex-direction: column;
	gap: spacing.$s300;
	margin-bottom: spacing.$s300;
}

.image {
	overflow: hidden;
	flex: 1 1 auto;

	@include container-query-with-fallback(breakpoints.$md) {
		max-width: br24-common.pixel-to-rem(400px);
	}
}

.metaModules {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--text-default-second);
}

.teaserText {
	@include container-query-with-fallback(breakpoints.$md) {
		overflow: hidden;
		text-overflow: ellipsis;

		@supports (-webkit-line-clamp: 1) {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
		}
	}
}
