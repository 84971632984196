@use 'spacing';

.chevron {
	flex-shrink: 0;
}

.linkWithChevron {
	display: flex;
	flex-direction: row;
	gap: spacing.$s400;
	align-items: center;
}
