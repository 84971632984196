// official BR blue color
$br-blue: #006afe;

:root,
[data-theme='light'],
[data-theme-area='light'] {
	::selection {
		background-color: rgba($br-blue, 0.5);
	}
}

[data-theme='dark'],
[data-theme-area='dark'] {
	::selection {
		background-color: rgba($br-blue, 1);
	}
}
