@use 'spacing';

.skipLink {
	position: absolute;
	z-index: 6;
	transform: translateX(-99999%);

	padding: spacing.$s200;

	color: var(--text-consistent-od-01);

	background-color: var(--background-default-third);

	transition: transform 0.3s;

	&:focus {
		transform: translateX(0);
	}
}
