@use 'spacing';
@use 'breakpoints';
@use 'br24-common';

$height: br24-common.pixel-to-rem(42px);

.formWrapper {
	margin-top: spacing.$s400;
	margin-inline: -(spacing.$s400);

	@media screen and (min-width: breakpoints.$lg) {
		margin-inline: 0;
	}
}

.form {
	display: grid;
	grid-template-columns: 1fr max-content;

	&.pending {
		pointer-events: none;
		opacity: 0.4;
	}
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;

	width: $height;
	height: $height;
	border: 1px solid var(--icon-consistent-od-01);

	color: var(--text-consistent-ol);

	background: var(--background-highlight-first);

	&:focus-visible {
		outline: var(--icon-consistent-ol) solid 1px;
		outline-offset: calc(-1 * var(--outline-offset));
	}
}

.input {
	min-width: 0;
	height: $height;
	padding-inline: spacing.$s200;
	border: 1px solid var(--icon-consistent-od-01);

	background: var(--background-consistent-second);
	outline-offset: calc(-1 * var(--outline-offset));

	&,
	&::placeholder {
		color: var(--text-consistent-od-01);
	}
}
