@use 'br24-common';

.container {
	pointer-events: none;
	opacity: 1;

	&.finished {
		opacity: 0;
	}
}

.bar {
	position: fixed;
	z-index: 1031;
	top: 0;
	left: 0;

	width: 100%;
	height: br24-common.pixel-to-rem(3px);

	background-color: var(--background-highlight-first);
}

.spinner {
	position: absolute;
	right: 0;
	transform: rotate(3deg) translate(0, -4px);

	display: block;

	width: 100px;
	height: 100%;

	opacity: 1;
	box-shadow:
		0 0 10px var(--background-highlight-first),
		0 0 5px var(--background-highlight-first);
}
