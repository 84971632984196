@use 'br24-common';
@use 'breakpoints';
@use 'spacing';
@use './HeaderVariables';

$menu-z-index: 2;

.header {
	// create a new stacking context
	isolation: isolate;
	position: sticky;
	z-index: br24-common.$header-z-index;
	top: 0;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	color: var(--text-consistent-od-01);
}

.mainMenu {
	position: absolute;
	z-index: $menu-z-index;

	// the main menu is placed always directly below the menu button
	top: HeaderVariables.$mobile-header-height;

	width: 100%;
	height: calc(100vh - HeaderVariables.$mobile-header-height);

	@media screen and (min-width: breakpoints.$lg) {
		right: 0;
		width: 33.33%;
		height: unset;
		max-height: calc(100vh - HeaderVariables.$mobile-header-height);
	}
}

.bayernMenu {
	position: absolute;
	z-index: $menu-z-index;

	// the main menu is placed always directly below the menu button
	top: HeaderVariables.$mobile-header-height;

	display: none;

	// this aligns bayern dropdown so that the content is aligned from top to bottom
	// width of icon, padding of icon, padding of bayern menu button, and subtract padding of menu button
	margin-left: br24-common.pixel-to-rem(45px) + (2 * spacing.$s400) + HeaderVariables.$highlight-navigation-padding-left - spacing.$s400;

	visibility: hidden;
	background-color: var(--background-default-third);

	&.open {
		display: block;
		visibility: visible;
	}
}
