@use 'spacing';

.topArticlesRoot {
	margin-top: spacing.$s800;
}

.title {
	display: block;
	margin-bottom: spacing.$s400;
	color: var(--text-default-first);
}

.article {
	margin-bottom: spacing.$s800;
}
