@use 'breakpoints';
@use 'spacing';
@use 'br24-common';

.social {
	display: flex;
	align-items: center;
	justify-content: center;

	padding: spacing.$s600 spacing.$s1500;

	background: var(--background-default-fifth);

	& > * {
		height: spacing.$s800;
	}
}

.iconWrapper {
	display: flex;
	flex: 1 0 0;
	justify-content: space-around;

	max-width: br24-common.pixel-to-rem(415px);

	list-style: none;
}

.socialLink {
	display: block;
}
