.dark {
	display: none;
}

[data-theme='dark'] {
	.light,
	&.light.light {
		display: none;
	}

	.dark,
	&.dark.dark {
		display: unset;
	}
}

[data-theme='light'] {
	.light,
	&.light.light {
		display: unset;
	}

	.dark,
	&.dark.dark {
		display: none;
	}
}
