@use 'spacing';
@use 'breakpoints';

.section {
	margin: spacing.$s0 spacing.$s700;
	padding: spacing.$s400 spacing.$s100 spacing.$s800;
	border-bottom: spacing.$s50 solid var(--background-button-second);

	@media screen and (min-width: breakpoints.$lg) {
		margin: 0;
		padding: spacing.$s0;
		border-bottom: none;
	}
}

.childWrapper {
	padding: spacing.$s0 spacing.$s400;
}

.navigationButton {
	width: 100%;
	color: var(--text-consistent-od-01);
}
