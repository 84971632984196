@use 'spacing';
@use 'br24-common';

.tabList {
	display: flex;
	align-items: flex-start;
	align-self: stretch;
	justify-content: space-around;

	width: 100%;

	& > button {
		display: flex;
		flex-direction: column;
		gap: spacing.$s100;
		align-items: center;

		height: br24-common.pixel-to-rem(61px) + spacing.$s400;
		padding: spacing.$s400 spacing.$s400 0;

		color: var(--text-consistent-od-01);

		& > svg {
			color: var(--text-consistent-od-01);
		}

		@media screen and (hover: hover) and (pointer: fine) {
			&:hover:not(:focus-visible) {
				outline: none;
			}
		}
	}
}

.tabButtonLabel {
	display: block;
}

.activeTabLine {
	display: block;
	width: 100%;
	border-bottom: 2px solid var(--background-highlight-first);
}

.tabContainer {
	display: flex;
	flex: 1;
	flex-direction: column;

	min-height: 0;

	background-color: var(--background-default-fifth);
}

.tabContent {
	overflow-y: auto;
	display: none;
	height: 100%;
	outline-offset: calc(-1 * var(--outline-offset));

	&.tabActive {
		display: block;
	}
}
