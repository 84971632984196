@use 'spacing';

.list {
	display: flex;
	gap: spacing.$s100;
	align-items: center;

	margin-left: auto;
	padding: 0;

	list-style: none;
}

.listItem {
	display: flex;
	margin-bottom: 0;
	padding: 0;

	&:last-child {
		margin-right: 0;
	}
}
