@use 'spacing';

.tooltip {
	display: flex;
	gap: spacing.$s200;
	align-items: flex-start;
	align-self: stretch;

	padding: spacing.$s100 spacing.$s200;

	color: var(--text-consistent-ol);

	background: var(--background-icon-second);

	&.success {
		background: var(--background-highlight-first);
	}
}
