// This file is autogenerated from the values exported from Figma
// DO NOT MODIFY BUT ONLY UPDATE BY RUNNING THE GENERATION SCRIPT.

@use 'br24-common';

$s0: br24-common.pixel-to-rem(0px);
$s50: br24-common.pixel-to-rem(2px);
$s100: br24-common.pixel-to-rem(4px);
$s200: br24-common.pixel-to-rem(8px);
$s300: br24-common.pixel-to-rem(12px);
$s400: br24-common.pixel-to-rem(16px);
$s500: br24-common.pixel-to-rem(20px);
$s600: br24-common.pixel-to-rem(24px);
$s700: br24-common.pixel-to-rem(28px);
$s800: br24-common.pixel-to-rem(32px);
$s900: br24-common.pixel-to-rem(36px);
$s1000: br24-common.pixel-to-rem(40px);
$s1500: br24-common.pixel-to-rem(60px);
