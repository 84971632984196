@use 'typo';

h1,
.heading1 {
	@include typo.heading1;
}

h2,
.heading2 {
	@include typo.heading2;
}

h3,
.heading3 {
	@include typo.heading3;
}

h4,
.heading4 {
	@include typo.heading4;
}

.body1 {
	@include typo.body1;
}

.body2 {
	@include typo.body2;
}

.body3 {
	@include typo.body3;
}

.body4 {
	@include typo.body4;
}

.body5 {
	@include typo.body5;
}

.body6 {
	@include typo.body6;
}

.body7 {
	@include typo.body7;
}
