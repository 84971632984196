@use 'spacing';
@use 'br24-common';
@use './FooterVariables';

$image-border-width: br24-common.pixel-to-rem(2px);

.wrapper {
	display: flex;
	flex-direction: column;
	gap: spacing.$s200;
	align-items: center;
	justify-content: center;
}

.scrollTopButton {
	cursor: pointer;

	display: flex;
	flex-direction: column;
	gap: spacing.$s200;
	align-items: center;
	justify-content: center;

	border: none;

	color: var(--text-consistent-od-01);

	background: none;

	& > span {
		white-space: nowrap;
	}
}

.image {
	width: FooterVariables.$scroll-top-button-dimension;
	height: FooterVariables.$scroll-top-button-dimension;
	padding: (FooterVariables.$scroll-top-button-dimension - br24-common.pixel-to-rem(32px) - 2 * $image-border-width) / 2;
	border: $image-border-width solid var(--background-icon-first);
	border-radius: 50%;

	color: var(--text-consistent-ol);

	background-color: var(--background-highlight-first);

	transition: transform 300ms;

	&:focus-visible {
		transform: scale(1.1);
	}

	@media screen and (hover: hover) and (pointer: fine) {
		&:hover {
			transform: scale(1.1);
		}
	}
}
