@use 'breakpoints';
@use 'spacing';
@use 'br24-common';
@use '../FooterVariables';

$side-column-width: br24-common.pixel-to-rem(240px);

.footer {
	display: grid;
	grid-template-areas:
		'social    social     social     social   '
		'bayern    rubriken   medien     app-theme'
		'bayern    newsletter newsletter app-theme'
		'impressum impressum  impressum  impressum';
	grid-template-columns: 2fr 1fr 1fr 2fr;
	gap: 0 spacing.$s800;
}

.newsletterSection.newsletterSection {
	border-bottom: none;
}

@media screen and (min-width: breakpoints.$lg) {
	.socialSection {
		grid-area: social;
	}

	.bayernSection.bayernSection {
		grid-area: bayern;
		justify-self: end;
		width: $side-column-width;
		padding-top: spacing.$s800;
	}

	.rubrikenSection.rubrikenSection {
		grid-area: rubriken;
		padding-top: spacing.$s800;
	}

	.medienSection.medienSection {
		grid-area: medien;
		padding-top: spacing.$s800;
	}

	.appTheme.appTheme {
		display: flex;
		grid-area: app-theme;
		flex-direction: column;
		gap: spacing.$s400;

		width: $side-column-width;
		padding-top: spacing.$s800;
	}

	.newsletterSection.newsletterSection {
		grid-area: newsletter;
		padding-top: br24-common.pixel-to-rem(22px);
		padding-bottom: spacing.$s800;
	}

	.impressumSection {
		grid-area: impressum;
	}
}

.scrollTop {
	position: absolute;
	top: -(FooterVariables.$scroll-top-button-dimension / 2);
	right: spacing.$s1000;

	justify-self: end;

	// the padding should be 400 but we have to account for the button that is moved to the top
	margin-bottom: -(FooterVariables.$scroll-top-button-dimension / 2) + spacing.$s400;
}
