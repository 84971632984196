.wrapper {
	position: relative;
	overflow: hidden;
	display: block;

	&::before {
		content: '';
		display: block;
		width: 100%;
	}

	> * {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}

	&.aspect169::before {
		padding-bottom: 9 / 16 * 100%;
	}

	&.aspect164::before {
		padding-bottom: 4 / 16 * 100%;
	}
}
