.checkbox {
	[data-theme='light'] & {
		color: var(--icon-default-third);
	}

	[data-theme='dark'] & {
		color: var(--icon-consistent-od-01);

		&.selected {
			color: var(--icon-highlight);
		}
	}
}
