@use 'spacing';
@use './HeaderVariables';

.highlightNavigation {
	overflow: hidden;

	// this will hide overflowing navigation nodes
	flex-wrap: wrap;
	gap: spacing.$s400;
	align-items: center;

	padding-left: HeaderVariables.$highlight-navigation-padding-left;

	color: var(--text-consistent-od-02);
}

.link {
	height: 100%;
	padding-inline: spacing.$s200;
	line-height: HeaderVariables.$mobile-header-height;
	white-space: nowrap;

	&:first-child {
		margin-left: -1 * spacing.$s200;
	}

	&.hidden {
		visibility: hidden;
	}
}
