@use 'br24-common';

.content {
	// create a stacking context for the article. This will allow for example the scroll
	// to top button to overlay the article content if a map is shown.
	isolation: isolate;
	position: relative;

	// the content should always be behind the header
	z-index: br24-common.$header-z-index - 1;
	flex-grow: 1;

	// if the light gallery is in maximize mode change the z-index of the article to a higher
	// value than the header to hide the header behind the article content
	:global(.lg-on) & {
		// the content should hide the header
		z-index: br24-common.$header-z-index + 1;
	}
}
