// This file is autogenerated from the values exported from Figma
// DO NOT MODIFY BUT ONLY UPDATE BY RUNNING THE GENERATION SCRIPT.

@mixin heading1() {
	font-size: 1.5rem;
	font-weight: 700;
	font-style: normal;
	line-height: 133.333%;
}

@mixin heading2() {
	font-size: 1.25rem;
	font-weight: 600;
	font-style: normal;
	line-height: 100%;
}

@mixin heading3() {
	font-size: 1.125rem;
	font-weight: 700;
	font-style: normal;
	line-height: 100%;
}

@mixin heading4() {
	font-size: 1.125rem;
	font-weight: 600;
	font-style: normal;
	line-height: 111.111%;
}

@mixin body1() {
	font-size: 1.125rem;
	font-weight: 600;
	font-style: normal;
	line-height: 155.556%;
}

@mixin body2() {
	font-size: 1.125rem;
	font-weight: 600;
	font-style: italic;
	line-height: 155.556%;
}

@mixin body3() {
	font-size: 1.125rem;
	font-weight: 400;
	font-style: normal;
	line-height: 155.556%;
}

@mixin body4() {
	font-size: 1.125rem;
	font-weight: 400;
	font-style: italic;
	line-height: 155.556%;
}

@mixin body5() {
	font-size: 1.125rem;
	font-weight: 600;
	font-style: normal;
	line-height: 155.556%;
}

@mixin body6() {
	font-size: 1.125rem;
	font-weight: 350;
	font-style: normal;
	line-height: 100%;
}

@mixin body7() {
	font-size: 1rem;
	font-weight: 350;
	font-style: normal;
	line-height: 112.5%;
}

@mixin weather9() {
	font-size: 3rem;
	font-weight: 400;
	font-style: normal;
	line-height: 100%;
}
