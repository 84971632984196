@use 'breakpoints';
@use 'spacing';

.wrapper {
	display: flex;
	flex-direction: column;

	height: 100%;
	padding: spacing.$s800 spacing.$s400;

	color: var(--text-default-first);

	background-color: var(--background-default-first);

	@media only screen and (min-width: breakpoints.$md) {
		padding: spacing.$s800 6rem;
	}

	@media only screen and (min-width: breakpoints.$xl) {
		padding: spacing.$s800 11rem;
	}
}

.image {
	margin: 0 auto spacing.$s800;
}
